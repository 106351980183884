/* DropDownButtons.module.scss */

.user-action-button {
  position: relative;
  .userActionBtn {
    color: #203662 !important;
    border-color: #203662 !important;
    font-size: 12px;
    font-weight: 600;
    border: solid 1px #203662;
    border-radius: 16px;
    padding: 8px 16px;
    min-width: auto !important;
    min-height: auto !important;
    cursor: pointer !important;
    line-height: normal !important;
    background-color: transparent !important;
    &:hover,
    &:active,
    &:focus {
      background-color: transparent !important;
      outline: none !important;
      box-shadow: none !important;
      cursor: pointer !important;
    }
    .icon {
      margin-left: 8px;
      width: 11px;
      height: 6px;
      cursor: pointer;
    }
  }
}
.menuPaper.MuiPaper-root {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  margin-top: 4px;
  padding-right: 2px;
  padding-top: 0px !important;
  border-radius: 12px !important;
  padding-right: 0px !important;

  .MuiList-padding {
    padding-top: 0px !important;
  }
  .menuItem {
    color: #1e1e1e;
    white-space: nowrap;
    font-weight: 600 !important;
    &:hover {
      background-color: #f3f6fa !important;
    }
    padding-top: 0px !important;
    padding-bottom: 0 !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    line-height: 32px !important;
  }
}
