.homeHeaderSection {
  background-color: #f2f6fa;
  border-radius: 6px;
  border: solid 1px #f2f6fa;
  // height: 48px;
  //max-width: 210px;
  width: 210px;
}

.homeIconDivider {
  padding-left: 15px;
  border-left: solid 1px #dae5f2;
  margin-left: 15px;
  display: inline-grid;

  span {
    line-height: normal;
    font-size: 12px;
    color: #1e1e1e;

    &:first-child {
      color: #66676b;
    }
  }
}

.secondryLinks {
  color: #203662;
  font-weight: 600;

  img {
    margin-right: 5px;
  }
}

.secondryLinksDivider {
  border-right: solid 1px #e6e6eb;
  margin-right: 12px;
  padding-right: 12px;
}

.headerDamsSection {
  span {
    line-height: normal;
    font-size: 12px;
    color: #1e1e1e;

    &:first-child {
      color: #66676b;
    }
  }
}

.minHeight16 {
  min-height: 16px !important;
}

.minHeight8 {
  min-height: 8px !important;
}

.mlrStatus {
  font-size: 12px;
  color: #54565b;
  gap: 4px;

  span {
    font-weight: 600;
  }
}