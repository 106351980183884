.notifications-container {
  position: relative;
  z-index: 99999;
  .icon-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    margin: 10px;
  }

  .flex {
    display: flex;
    gap: 8px;
  }

  .sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 400px;
    height: 100vh;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    padding: 20px;
    z-index: 100;
    border-radius: 12px 0 0 12px;

    .sidebar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      h2 {
        font-size: 18px;
        margin: 0;
      }

      .close-btn {
        background-color: transparent;
        border: none;
        font-size: 14px;
        color: #203662;
        cursor: pointer;
      }
    }

    .sidebar-content {
      flex-grow: 1;
      // overflow-y: auto;

      .sidebar-filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .filter-btn,
        .reset-btn {
          color: #203662;
          font-weight: 600;
          border: none;
          cursor: pointer;
          background: none;

          &:disabled {
            background: none;
            border: none;
            cursor: not-allowed;
            color: #999;
          }
        }
      }

      .notifications-list {
        height: calc(100vh - 145px);
        overflow-y: scroll;
        overflow-x: hidden;
        margin-right: -16px;

        .notification-item {
          width: 360px;
          height: 68px;
          font-size: 12px;
          font-weight: 600;
          border: 1px solid #eee;
          padding: 13px 12px;
          border-radius: 6px;
          margin: 0 0 12px;
          background-color: #f2f6f9;
          cursor: pointer;

          .notification-type {
            font-weight: bold;
            margin-bottom: 10px;
            color: #1e1e1e;
          }

          .notification-id {
            color: #203662;
            font-weight: bold;
            display: flex;
            gap: 6px;
            div:first-child {
              color: #54565b;
              font-weight: normal;
              display: inline-flex;
            }
          }

          //   .notification-status {
          //     color: #dc3545;
          //     font-weight: bold;
          //   }

          .notification-date {
            font-size: 12px !important;
            font-weight: normal !important;
            color: #a9aaac !important;
          }
        }
      }
    }
  }
}

.markas {
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid #dddfdd;
  color: #1e1e1e;
  font-size: 12px;

  &>div:nth-child(1) {
    color: #1e1e1e;
  }

  &>div:nth-child(2) {
    color: #203662;
    font-weight: 700;
    cursor: pointer;
  }
}

.markas button {
  background: none;
  border: none;
  padding: 0;
  color: #203662;
  font-weight: 700;
  cursor: pointer;
  font-size: inherit;

  &:disabled {
    color: #a9aaac;
  }
}


.custom-dropdown-item {
  cursor: pointer;
  padding: 10px 15px;

  &:hover {
    background-color: #f0f5f8 !important;
  }

  &.selected {
    color: rgb(32, 54, 98);
  }
}