@import "../base/universal";

.dashboardBg {
}
.dashboard-imc-logo {
  height: 30px;
  margin-left: 25px;
  margin-top: 25px;
  //opacity: 0.92;
  //font-family: Raleway;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #1c75bb;
}

.dashboardLinks {
  background-color: #1c75bb;
  padding: 10px;
  border-radius: 15px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  //width: 175.5px;
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 0%;
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  box-shadow: 6px 6px 34px 0 rgba(0, 0, 0, 0.11);

  a {
    color: #bad5ea;
    text-decoration: none;
  }
  a:hover {
    color: #ffffff;
  }
}
.dashboardLinks .MuiList-root:after {
  display: contents;
}

.dashboardLinks ul {
  margin: 0;
  padding: 0;
  margin-left: 0px;

  li {
    list-style: none;
    font-size: $font-small; //10px;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-left: 10px;
    position: relative;
    color: #bad5ea;
    padding-left: 0px;
    padding-right: 25px;
    cursor: pointer;
    img {
      margin-right: 15px;
    }
    button {
      color: #fff;
      font-size: 8px;
      padding: 0;
      font-weight: normal;
    }
    button:hover {
      color: #fff;
    }
  }
  li:hover {
    color: #ffffff;
  }
  .li::selection {
    color: #ffffff;
  }
}
.dasboardContainer {
  position: relative;
}
.dasboardContent {
  // margin: 0;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // -ms-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);
  // width: 100%;
  // height: 100%;
  // overflow-x: hidden;
}

.dasboardTitle {
  color: #3181c0;
  font-size: 52px;
  position: relative;
}
.userIcon {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  position: relative;
}
.userOnlineIcon {
  background-color: #00de67;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  position: absolute;
  left: 14px;
  bottom: 15px;
}
.userNameInfo {
  margin-top: -3px;
  display: block;
  font-size: $font-small; //10px;
  color: $imc-white-color;
}
.userDesignation {
  color: #bad5ea;
  display: block;
  font-size: $font-xsmall; //8px;
  // opacity: 0.6;
  text-align: left;
}
.userDesignation:hover {
  color: #ffffff;
}

@media (max-width: 767.98px) {
  .dasboardTitle {
    font-size: 28px;
  }
  .dasboardContent {
    margin: 0;
    position: absolute;
    top: 35%;
    left: 75%;
    transform: translate(-60%, -35%);
  }
}

@media all and (max-width: 1064px) and (min-width: 768px) {
  .btn.browse {
    min-width: 120px !important;
  }
}
.right-arrow {
  // width: 9px;
  // height: 143px;
  // margin: -246px 0px 0px 147px;
  // padding: 68px 2.3px 68.3px 3px;
  // border: solid 1px #1c75bb;
  // background-color: #1c75bb;
  // margin-top: 309px;
  height: 143px;
  width: 10px;
  background-color: #1c75bb;
  cursor: pointer;
  color: white;
  border-radius: 0px 11px 11px 0px;
  // position: absolute;
  // z-index: 9999;

  // margin-left: -25px !important;
}
.floatingMenu {
  position: fixed;
  top: 50%;
  left: 0;
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  z-index: 9999;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: -13px;
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
}
.menu-item {
  margin-left: 34px;
  margin-bottom: 0rem;
  font-size: $font-small; //10px;
}
.module-item {
  margin-left: -3px !important;
}
.dashboardLinks ul.MuiList-root {
  margin-left: 0;
}
