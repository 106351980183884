@import "../base/universal";
.btnOutline {
  border-radius: 6px;
  border: dashed 1px #203662;
  text-align: center;
  color: #203662;
  font-weight: 700;
  font-size: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-transform: uppercase;
  img {
    margin-top: -2px;
  }
  &:hover {
    color: #203662;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    background-color: #f2f3f3;
    border: dashed 1px #d4d4d4;
    color: #878888;
  }
  &:disabled img {
    opacity: 0.5;
    filter: grayscale(100%);
  }
}
