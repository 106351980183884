@import "../base/universal";

.blue-tile-strip {
  background-image: $img-background-gradient-blue;
  height: 100px;
  padding: 0px 20px 0px 20px;
  color: $imc-white-color;
  display: flex;
  align-items: center;
}
.blue-tile-text {
  flex: 0 0 488px;
}
.tab-panel-box {
  flex: 0 0 130px;
  text-align: center;
  height: 100px;
  // line-height: 100px;
  ul {
    list-style: none !important;
    margin: 0;
    padding: 25px 0px 0px 0px;
  }
}
.choose-title {
  font-size: $font-small-v3;
  font-weight: bold;
}
.proceed-title {
  font-size: $font-small-v2;
  font-weight: normal;
  display: block;
}
.tab-panel-box-active {
  background-color: #c50f3c;
  font-size: $font-small-v2 !important;
  font-weight: bold;
}
.topNavigationRow {
  background-color: #203662;
  color: $imc-white-color;
}
.topRowColum1 {
  flex: 0 0 calc(50% - 100px);
  padding-top: 27px;
  padding-bottom: 27px;
  padding-left: 20px;
}
.link-block {
  // align-items: center;
  // display: flex;
  text-align: center;
  padding: 0 36px;
  cursor: pointer;
  padding-top: 17px;
  position: relative;
  img {
    margin-bottom: 10px;
  }
  div {
    span {
      display: block;
    }
  }
  &:hover {
  }
}
.link-block.active {
  background-color: #c50f3c;

  font-size: 14px;
  font-weight: 600;
}
.link-block.active::after {
  content: "";
  width: 30px;
  background-image: url("../../img/TriangleTipIcon.svg");
  background-position: bottom center;
  background-position: bottom center;
  height: 20px;
  position: absolute;
  bottom: 0;
  background-repeat: no-repeat;
  background-position-y: 8px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.mt6 {
  margin-top: -6px;
}
