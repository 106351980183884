@import "../base/universal";

.componentCardParent {
  flex: 0 0 20%;
  padding-top: 20px;
  padding-right: 0px !important;
  position: relative;
}

.componentCard {
  border-radius: 6px;
  margin-right: 20px;
  border: 1px solid $imc-border-color;
  cursor: pointer;

  .imageBlock {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 126px;
    border: 1px solid $imc-border-color;
    position: relative;
    border-radius: 6px;
    margin: 12px 10px 12px;
    background-color: #f3f6fa;
    cursor: pointer;
  }

  // .imageBlock:before {
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   border-radius: 6px;
  // }
  // .imageBlock:hover::before {
  //   -webkit-backdrop-filter: blur(2px);
  //   backdrop-filter: blur(2px);
  //   background-color: rgba(0, 0, 0, 0.5);
  // }
  // .imageBlock img {
  //   display: block;
  // }
  // .imageBlock div {
  //   position: absolute;
  //   top: 60%;
  //   left: 50%;
  //   transform: translate(-50%, -70%);
  //   -ms-transform: translate(-50%, -50%);
  //   opacity: 0;
  // }
  // .imageBlock:hover div {
  //   opacity: 1;
  // }
}
.componentCardDisabled {
  background-color: $imc-disabled-bg-color;
}
.componentCardSelected {
  background-color: #f3f6fa;
  cursor: pointer;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
}
.imageSelectionTick {
  position: absolute;
  z-index: 9999;
  right: 15px;
  top: 15px;
}
.imgThumbBorder {
  // border: 1px solid $imc-border-color;
  border-radius: 6px;
}
