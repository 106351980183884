@import "../base/universal";

.tf-rectangular-search {
  border-radius: 6px;
  border: solid 1px $imc-border-color;
  word-wrap: normal;
  height: 42px !important;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  input {
    border: none !important;
    background-color: transparent;
    width: 100%;
    color: #1e1e1e;
    height: 100%;
    padding: 8px 6px;
  }
  input:focus {
    outline: 0;
  }
  input::placeholder {
    font-size: $font-small !important;
    color: #1e1e1e !important;
    font-weight: 600 !important;
  }
}

.search-icon {
  height: 12px;
  width: 12px;
}

.search-input {
  color: #1e1e1e !important;
  //margin-left: 5px !important;
  font-size: 12px !important;
  margin-right: 0px;
}
.search-input::placeholder {
  color: #1e1e1e !important;
  font-size: 12px !important;
}
.cross-icon {
  cursor: pointer;
}
