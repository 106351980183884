@import "styles/base/_universal.scss";
.businessrulesBox {
  border-radius: 12px;
  border: solid 1px rgba(3, 78, 162, 0.1);
  background-color: #f3f6fa;
  padding: 12px;
  // height: 300px;
}
.businessRulesTitle {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.businessRulesSubTitle {
  font-size: 12px;
  font-weight: 700;
  //text-transform: uppercase;
  color: #1e1e1e;
}
.customTextFiled {
  border-radius: 6px;
  border: solid 1px #d4d4d4;
  width: 100%;
  padding: 10px 12px;
  resize: none;
  font-size: 12px;
  color: #1e1e1e;
  &::placeholder {
    font-size: 12px;
    color: #1e1e1e;
  }
  &:focus-visible {
    outline: none;
  }
}
.addedClaimBox {
  border: solid 1px #dae5f2;
  border-radius: 12px;
  background-color: #fff;
  padding: 16px 12px;
  position: relative;
  margin-bottom: 12px;
  // width: 80%;
}
.claimBox {
  position: relative;
  cursor: move;
  padding: 16px 12px;
  border-radius: 12px;
  border: solid 1px #dae5f2;
  background-color: #fff;
  margin-bottom: 12px;
  &.disabled {
    opacity: 0.5;
    cursor: default;
    user-select: none;
    color: red;
    button {
      visibility: hidden !important;
    }
  }
}
.claimBox.disabledClaimbox {
  cursor: default;
  user-select: none;
  .cardBrandDetails {
    color: #878888 !important;
    span {
      color: #878888 !important;
    }
  }
}
.rulesTitle {
  font-weight: 700;
  min-height: 16px;
  color: #252529;
}
.dropPlaceHolder {
  font-size: 12px;
  color: #878888;
}
.moduleFooterRow {
  box-shadow: 1px -3px 10px 0 rgba(0, 0, 0, 0.07) !important;
  background-color: #fff;
  padding-top: 14px;
  padding-bottom: 14px;
  position: relative;
}
.bsLeftColumn {
  border-right: solid 1px #e6e6ec;
  flex: 0;
}
.searchSubTitle {
  font-size: 12px;
  color: #54565b;
  padding-bottom: 20px;
  border-bottom: solid 1px #e6e6ec;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  white-space: nowrap;
}
.moduleSearchResult {
  height: calc(100vh - 436px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 12px;
  margin-right: -11px;
  padding-right: 11px;
}
.cardBrandDetails {
  color: #1e1e1e;
  font-size: 12px;
  font-weight: 600;
  span {
    color: #54565b;
  }
}
.deleteCardIcon {
  position: absolute;
  right: -5px;
  top: -5px;
  cursor: pointer;
}
.bsrulesList {
  height: calc(100vh - 460px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 6px;
}

.componentimage {
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  justify-content: center;
  margin-right: 12px;
}

.placeholderImg {
  padding: 14px;
}

.thumbImg {
  padding: 5px;
  background-color: $imc-white-color !important;
  border: solid 1px $imc-border-color;
  height: 42px;
  width: 48px;
  flex: 0 0 48px;
}

.bruleSourceModuleCard {
  background-color: $imc-pale-orange;
}
.cntnrbruleSourceModuleCard {
  padding: 12px 0px 8px 0px;
  border-bottom: 1px dashed $imc-border-color;
  margin-bottom: 12px;
}

.emptylist {
  position: relative;
  height: 100%;
  .emptyMessage {
    position: absolute;
    top: calc(50% - 30px);
    // right: 50%;
  }
  .doneIcon {
    text-align: center;
    margin-bottom: 12px;
  }
}
.textAreaContainer {
  position: relative;
}

.customTextFiled {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  box-sizing: border-box;
  resize: none;
  overflow-y: auto;
}

.charCount {
  position: absolute;
  top: -12px;
  right: 20px;
  padding: 4px 8px;
  font-size: $font-small;
  color: $imc-pale-gray-filter;
  background-color: rgba(255, 255, 255, 0.8);
  pointer-events: none;
}
