.textfield-container {
  position: relative;
}

.char-count {
  position: absolute;
  top: 5px;
  right: 12px;
  font-size: 10px;
  color: #949494;
  display: none;
}

.textfield-container.focused .char-count {
  display: block;
}
