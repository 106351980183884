//Here define universal variables to be used through out the app

//Colors
$imc-app-background-color: #ebf1f8;
$imc-theme-color: #203662;
$imc-grey-color: #e9e9e9;
$imc-white-color: #fff;
$imc-subtitle-color: #54565b;
$imc-darkgrey-color: #1e1e1e;
$imc-grey2-color: #f5f5f5;
$imc-dark-charcol-color: #333333;
$imc-disabled-text-color: #878888;
$imc-border-color: #d4d4d4;
$imc-bg-selected: #eff8ff;
$stylise-module-color: #f2f4ff;
$imc-primary-color: #203662;
$imc-alice-blue-color: #eef8ff;
$imc-white-smoke-color: #f4f4f4;
$imc-light-border-color: #ebf1f8;
$imc-pale-gray-color: #f3f6fa;
$imc-disbaled-field-color: #f2f3f3;
$imc-aubergine-color: #1e1e1e;
$imc-red-color: #e32b35;
$imc-light-blue: #f5f5f7;
$imc-black-color: #1e1e1e;
$imc-disabled-bg-color: #f8f8f9;
$imc-gray30-color: #4d4d4d;
$imc-pale-blue-color: #f2f6fa;
$imc-pale-orange: #fff8ee;
$imc-pale-green: #d1e6e6;
$imc-pale-gray-tab: #1e1e1e;
$imc-red-pink: #f11946;
$imc-pale-gray-filter: #949494;
//Fonts
$font-xssmall: 10px;
$font-xsmall: 11px;
$font-small: 12px;
$font-small-v2: 14px;
$font-small-v3: 16px;
$font-22px: 22px;

$font-open-sans: "Open Sans", sans-serif;

//Font-weights
$font-wight-600: 600;
$font-wight-400: 400;
$font-weight-700: 700;

//Background-image
$img-background-gradient-blue: linear-gradient(
  to top,
  #203662 0%,
  #2162d5 90%
); //linear-gradient(to top, #203662, #2162d5 -13%);
$img-background-gradient-active: linear-gradient(
  to bottom,
  #ed0677 -21%,
  #203662 113%
);

:export {
  appBgColor: $imc-app-background-color;
  themeColor: $imc-theme-color;
  grayColor: $imc-grey-color;
  whiteColor: $imc-white-color;
  subtitleColor: $imc-subtitle-color;
  darkGrayColor: $imc-darkgrey-color;
  gray2Color: $imc-grey2-color;
  darkCharcolRequired: $imc-dark-charcol-color;
  disabledTextColor: $imc-disabled-text-color;
  borderColor: $imc-border-color;
  bgSelected: $imc-bg-selected;
  styliseModuleColor: $stylise-module-color;
  primaryColor: $imc-primary-color;
  aliceBlueColor: $imc-alice-blue-color;
  whiteSmokeColor: $imc-white-smoke-color;
  lightBorderColor: $imc-light-border-color;
  blackColor: $imc-black-color;
  paleGrayColor: $imc-pale-gray-color;
  disabledFieldColor: $imc-disbaled-field-color;
  searchTextColor: $imc-aubergine-color;
  redColor: $imc-red-color;
  lightBlueColor: $imc-light-blue;
  disabledBgColor: $imc-disabled-bg-color;
  gray30Color: $imc-gray30-color;
  paleBlueColor: $imc-pale-blue-color;
  paleGreenColor: $imc-pale-green;
  tabGrayColor: $imc-pale-gray-tab;
  redPinkColor: $imc-red-pink;
  grayFilterColor: $imc-pale-gray-filter;
  //Fonts
  fontXssmall: $font-xssmall;
  fontXsmall: $font-xsmall;
  fontSmall: $font-small;
  fontSmallV2: $font-small-v2;
  fontSmallV3: $font-small-v3;
  font22px: $font-22px;

  fontOpenSans: $font-open-sans;

  //Font-weights
  fontWeight600: $font-wight-600;
  disabledColor: #878888;
  fontWeight700: $font-weight-700;
  paleOrange: $imc-pale-orange;
}
