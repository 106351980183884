.moduleBrandsRow {
  // color: #1e1e1e;
  // span {
  //   color: #54565b;
  //   display: inline-block;
  //   &:nth-child(2) {
  //     margin-left: 15px;
  //   }
  // }
  .moduleBrandsRowBrdr {
    border-bottom: solid 1px #e6e6ec;
    // padding-top: 20px;
    // padding-bottom: 20px;
    margin: 0px -15px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
}
.editCtaBrdrleft {
  border-left: solid 1px #e6e6eb;
  padding-left: 16px;
  margin-left: 16px;
}
.segmentCount {
  width: 20px;
  height: 20px;
  padding: 3px 4px;
  background-color: #d1e6e7;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  color: #1e1e1e;
  display: inline;
  margin-left: 4px;
}

.mlrStatus {
  font-size: 12px;
  color: #54565b;
  gap: 4px;
  span {
    font-weight: 600;
  }
}
