@import "../base/universal";

.multiselect-dropdown {
  padding: 6px 13px 8px;
  font-size: $font-small;
  border-radius: 16px;
  font-weight: $font-wight-600;
  cursor: pointer;
  height: 32px;
}
.multiselect-dropdown.active {
  border: solid 1px $imc-theme-color;
}
.multiselect-dropdown.disabled {
  cursor: default;
  color: $imc-disabled-text-color;
}

.multi-select-dropdown-outer {
  z-index: 9999;
  // box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.16);
  top: 0px !important; //UX feedback
  right: 0 !important;
  border-radius: 12px;
  width: 208px;
  .MuiPaper-root {
    padding-bottom: 0;
    padding-top: 0;
  }
}
