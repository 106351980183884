@import "../base/universal";

.filter-container {
  background-color: $imc-white-color;
  padding: 20px 0px;

  .col-md-3 {
    padding-left: 20px;
  }

  .col-md-9 {
    padding-right: 20px;
  }
}

.btn-grp-createdby {
  height: 32px;
  border-radius: 16px;
  border: solid 1px $imc-light-border-color;
  width: fit-content;
  white-space: nowrap;
}

.dashboard-header {
  height: 60px;
  padding: 12px 2px 12px 0px;
  background-color: #fff;
}

.nextIcon {
  margin-left: 12px;
}

.profileIcon {
  margin-left: 16px;
}

.sourceText {
  font-size: $font-small;
  color: #66676b;
  font-weight: normal;
  letter-spacing: 0.12px;
}

.promotext {
  font-size: $font-small;
  font-weight: $font-wight-600;
  color: #1e1e1e;
  margin-top: -2px;
}

.userName {
  font-size: 12px;
  color: #fff;
  background-color: $imc-theme-color;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-weight: $font-wight-600;
  margin-left: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifyIcon {
  float: right;
}

.srcText {
  float: right;
}

.dashBoardIcon {
  width: 32px;
  height: 32px;
  background-image: url("../../img/dotsIcon.svg");
  background-repeat: no-repeat;
  background-size: 32px 32px;
  cursor: pointer;
}

.show .dashBoardIcon {
  background-image: url("../../img/dotsIconActive.svg");
}

.dasbhoardSearchResult {
  height: calc(100vh - 255px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 0 !important;
  // margin-right: -11px;
}

.dasbhoardSearchResultWithFilter {
  height: calc(100vh - 288px) !important;
}

.moduleCardBlock {
  flex: 0 0 20%;
  margin-bottom: 20px;
}

.cardHover:hover {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.24);
  transform: translateY(-5x);
}

.dasboardSearchCard {
  border-radius: 6px;
  background-color: #fff;
  margin-right: 20px;
  border: solid 1px #e6e6eb;
  position: relative;

  .bsTickIcon {
    position: absolute;
    z-index: 9999;
    right: 0;
  }

  .imgBlock {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-bottom: solid 1px #e6e6eb;
    height: 126px;
    position: relative;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  .imgBlockWithHover {

    // &:hover {
    //   -webkit-backdrop-filter: blur(2px) !important;
    //   backdrop-filter: blur(2px) !important;
    //   background-color: rgba(0, 0, 0, 0.5) !important;
    //   opacity: 1;
    // }
    .previewOverlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .previewOverlayWithMessage {
      position: absolute;
    }
  }

  .contentBlock {
    padding: 12px;
    padding-bottom: 0px;

    p {
      margin-bottom: 12px !important;
      color: #1e1e1e;
      font-weight: 600;
      line-height: normal !important;

      span {
        font-size: 12px;
        color: #54565b;
        font-weight: normal;
      }
    }

    p.mtitle {
      font-size: 12px;
      font-weight: 700;
      color: #1e1e1e;

      img {
        margin-top: -2px;
        margin-right: 6px;
      }
    }

    .moduleName {
      font-size: 12px;
      font-weight: 700;
      color: #1e1e1e;
    }
  }

  .cardFooter {
    padding: 0 12px 11px 12px;

    img {
      margin-top: -2px;
      margin-right: 3px;
    }
  }
}

.logOutButton .dropdown-menu {
  min-width: 184px !important;
  max-width: 184px !important;
}

.cardOverLay {
  display: none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.imgBlock.imgBlockWithHover {
  &:hover .cardOverLay {
    display: flex;
  }
}

.tooltip-text {
  display: flex;
  align-items: center;
  width: 200px;
  height: 40px;
  // padding: 4px !important;
  font-size: 12px;
  color: #1e1e1e;
}

.tooltip-text-Bell-icon {
  display: flex;
  align-items: center;
  width: 200px;
  height: 60px;
  // padding: 4px !important;
  font-size: 12px;
  color: #1e1e1e;
}

.bell-icon {
  cursor: pointer;
}

.bell-icon {
  width: 39px;
  height: 35px;
  padding: 6px 7px 6px 7.5px;
  background-color: #f0f5f8;
  border-radius: 100%;
}