@import "styles/base/_universal";
@mixin text-ellipsis($lines: 1) {
  text-overflow: ellipsis;
  overflow: hidden;
  @if ($lines > 1) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  } @else {
    white-space: nowrap;
  }
}

.elementsCard {
  border-radius: 6px;
  background-color: #fff;
  color: #1e1e1e;
  margin-bottom: 12px;
  .elementsTitleRow {
    border-bottom: solid 1px #e6e6ec;
    padding: 12px;
    font-weight: 600;
    line-height: normal;
    span {
      display: inline-block;
    }
  }

  span.titleBlock {
    min-width: 66px;
    padding-right: 20px;
  }
  span.idBlock {
    font-weight: normal;
    color: #54565b;
  }
  .elementsContent {
    overflow: hidden;
    padding: 0 12px;
    height: 30px;
    margin-top: 12px;
    margin-bottom: 12px;
    line-height: normal;
    span {
      display: inline-block;
    }
    .ellipsses {
      line-height: normal;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .elementsFooter {
    padding: 8px 12px;
    padding-bottom: 6px;
    font-weight: 600;
    padding-top: 0;
    line-height: normal;
    span {
      display: inline-block;
    }
  }
  .switchButtonRow {
    margin-left: 12px;
    margin-right: 12px;
    border-top: solid 1px #e6e6ec;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 6px;
  }
  .thumbnailcontainer {
    border-radius: 6px;
    position: relative;
    width: 100%;
    max-width: 100px;

    height: 85.8px;
    width: 88px;
    border-radius: 6px;
    border: solid 1px $imc-border-color;
  }
  // .thumbnail {
  //   height: 85.8px;
  //   width: 88px;
  //   border-radius: 6px;
  // }

  // .container {
  //   position: relative;
  //   width: 100%;
  //   max-width: 100px;
  // }
  // .thumbnailimagecontainer {
  //   border-radius: 6px;
  // }
  .thumbnailcontainer:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 6px;
  }
  .thumbnailcontainer:hover::before {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.5);
  }
  .thumbnailcontainer img {
    display: block;
  }
  .thumbnailcontainer div {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -70%);
    -ms-transform: translate(-50%, -50%);
    opacity: 0;
  }
  .thumbnailcontainer:hover div {
    opacity: 1;
  }
}
.elementsCard:last-child {
  margin-bottom: 0px;
}
