@import "../base/universal";
.paginationBtn {
  background-repeat: no-repeat;
  width: 15.2px;
  height: 15.2px;
  border: 0;
  background-color: transparent;
  padding-right: 0;
}
.btnPaginationLeftDisabled {
  background-image: url("../../img/smallLeftScrollActive.svg");
  margin-right: 16px;
  &:disabled {
    background-image: url("../../img/smallLeftScroll.svg");
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.btnPaginationRightDisabled {
  background-image: url("../../img/smallRightScrollActive.svg");
  margin-left: 16px;
  &:disabled {
    background-image: url("../../img/smallRightScroll.svg");
  }
}
.lableText {
  font-size: 12px;
  color: #222;
  margin-left: 4px;
  line-height: 1.9;
}
.sliderInput {
  width: 23px;
  height: 20px;
  border-radius: 4px;
  border: solid 1px #e6e6eb;
  background-color: #fff;
}
