@import "../base/universal";
.elementsBlockContainer {
  // height: calc(100vh - 300px);
}
.elementxBox {
  border-radius: 12px;
  background-color: #f2f6fa;
  border: dashed 1px #d4d4d4;
  height: calc(100vh - 363px);
  padding: 12px 16px 12px 16px;
  position: relative;
  &.elementxBoxBrdr {
    border-radius: 12px;
    border: dashed 1px #d4d4d4;
    background-color: #fff;
    .title {
      font-size: 12px;
      font-weight: bold;
      color: #222;
      line-height: normal;
      padding-bottom: 10px;
      display: block;
    }
  }
  .title {
    font-size: 12px;
    font-weight: bold;
    color: #222;
  }
}
.moduleFooterRow {
  box-shadow: 1px -3px 10px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
  padding-top: 14px;
  padding-bottom: 14px;
}
.addElementsOutline {
  border-radius: 6px;
  border: dashed 1px #203662;
  text-align: center;
}
.moduleFooterRow1 {
  //box-shadow: 1px -3px 10px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
  padding-top: 14px;
  padding-bottom: 14px;
  border: solid 1px #e6e6ec;
}
.moduleFooterRowInsideCard {
  bottom: 0;
  position: absolute;
  border-top: solid 1px #e6e6ec;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 100%;
  left: 0;
  text-align: center;
}
.claimsRow {
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.claimBoxType1 {
  flex: 0 0 40% !important;
}
.claimboxPadding {
  padding-right: 15px;
  padding-left: 15px;
}
.elementxBox.smallBox {
  height: 48.6%;
  // height: calc(100vh - 556px);
}

.descriptionbox {
  height: calc(100vh - 458px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 12px 0px;
  font-weight: normal;
  color: $imc-darkgrey-color;
}
.fgrbox {
  height: calc(100% - 38px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0px 0px 8px 0px;
  font-weight: normal;
  color: $imc-darkgrey-color;
}
ul.claimsMetaList {
  margin: 0px !important;
  padding: 0px !important;
  li {
    list-style: none;
    line-height: normal;
    font-weight: 600;
    color: #1e1e1e;
    span {
      display: inline-block;
      border-left: solid 1px #e6e6eb;
      padding-left: 12px;
      margin-left: 12px;
      font-weight: normal;
      color: #54565b;
    }
  }
}

.sliderheight {
  height: calc(100vh - 363px);
  padding-right: 0.9px;
}

.flexWidth20 {
  flex: 0 0 20%;
}
.componetImgContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 403px);
  padding-right: 3px;
}
.flexWidth30 {
  flex: 0 0 30%;
}
