@import "../base/universal";
.titleBackground {
  border-bottom: solid 1px #e6e6ec;
  padding-bottom: 16px;
}
.previewTitle {
  line-height: normal;
  font-weight: 700;
  color: #252529;
  span {
    font-size: 12px;
    color: #54565b;
    display: block;
    font-weight: normal;
  }
}
.mlrStatus {
  font-size: 14px;
  color: #1e1e1e;
  span {
    font-weight: 600;
  }
}
.plr110 {
  padding-left: 110px;
  padding-right: 110px;
  min-height: calc(100vh - 227px);
}
.modulePreviewContainer {
  border: solid 1px #e6e6eb;
}
.modulePreviewHeader {
  padding: 20px;
  border-bottom: solid 1px #e6e6eb;
  background-color: #f3f6fa;
  word-break: break-all;
  p {
    font-weight: normal;
    color: #54565b;
    margin-bottom: 10px;
    line-height: 22px;
    font-size: 14px;
    font-weight: 600;
    color: #1e1e1e;
    span {
      font-weight: normal;
      color: #54565b !important;
      margin-left: 20px;
      margin-right: 2px;
      display: inline-block;
    }
  }
}
.moduleClaimSection {
  padding: 10px 20px 40px 20px;
}

.claimBoxBrdr {
  padding: 12px;
  border-radius: 10px;
  border: solid 1px #e6e6eb;
  background-color: #fff;
  margin-right: 20px;
  line-height: normal;
  .claimType {
    font-weight: bold;
    color: #1e1e1e;
    text-transform: uppercase;
    margin-bottom: 6px;
  }
  .damStatus {
    margin-bottom: 6px;
    img {
      margin-right: 3px;
      margin-top: -2px;
    }
    span {
      display: inline-block;
      line-height: 12px;
      font-weight: 600;
      color: #1e1e1e;
      vertical-align: middle;
    }
  }
  .claimId {
    span {
      color: #203662;
      font-weight: 700;
    }
  }
  .claimTypeText {
    font-weight: normal;
    color: #54565b;
    margin-bottom: 6px;
    span {
      font-weight: 600;
    }
  }
}
.claimDetailsBox {
  padding-left: 20px;
  flex: 1 1 0;
}
.claimText {
  font-weight: 600;
  color: #1e1e1e;
  margin-bottom: 20px;
}
.captionText {
  font-weight: 600;
  color: #54565b;
  margin-bottom: 10px;
  margin-top: 20px;
  text-transform: uppercase;
}
.relatedText {
  font-weight: 600;
  color: #54565b;
  margin-bottom: 12px;
  margin-bottom: 0 !important;
  a {
    color: #54565b;
    text-decoration: none;
  }
}
.sectionDevider {
  border-bottom: dashed 1px #878888;
  margin-bottom: 40px;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.modulePreviewFooter {
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 1px -3px 10px 0 rgba(0, 0, 0, 0.07) !important;
  background-color: #fff;
  text-align: right;
  position: relative;
}
.dropdownClass {
  // ul {
  //   margin: 0;
  //   padding: 0;
  // }
  // margin-bottom: 0px;
  // a {
  //   font-size: 16px;
  //   padding: 8px 89px 8px 0px;
  //   letter-spacing: 0.12px;
  //   text-align: left;
  //   color: #1e1e1e;
  //   &:hover {
  //     color: #203662;
  //     background-color: transparent;
  //   }
  // }
  // width: 180px !important;
}
.modulePrviewContainer {
  height: calc(100vh - 224px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.bsrulesPreview {
  .modulePrviewContainer {
    height: calc(100vh - 281px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
//Brules Preview
.brulesTable {
  border-collapse: collapse;
  width: 100%;
}
.brulesTableHeading {
  background-color: #203662;
}
.tdHeading {
  font-weight: bold;
  color: #fff;
}
.tdCommon {
  font-size: 14px;
  text-align: left;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 21px !important;
  border: solid 1px #e6e6eb;
}
.tdlable {
  font-weight: 600;
  color: #1e1e1e;
}
.tdValue {
  font-weight: 600;
  color: #203662;
}
.tdValue2 {
  font-size: 14px;
  color: #1e1e1e;
}
.tdForValueTable {
  border: solid 1px #e6e6eb;
}
.commonPadding {
  padding-left: 110px;
  padding-right: 110px;
}
.brulesTableNoBrdr td {
  border-left: 0;
  border-right: 0;
  &:last-child {
    border-bottom: 0;
  }
}

.usageGuidilines {
  font-weight: normal;
  color: $imc-aubergine-color;
  margin-bottom: 0;
}
.pdfPreviewWidth {
  max-width: 1100px;
  min-width: 1100px;
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
}
/*Track changes styles starts */
.bsOld {
  background-color: #d4d4d4 !important;
  display: inline-block;
  border-radius: 4px;
  padding: 2px 5px;
  font-size: 14px;
  font-weight: 600;
  color: #1e1e1e;
  margin-bottom: 6px;
  text-transform: capitalize;
  margin-top: 15px;
}
.cmsdTrackContainer {
  .newData {
    display: inline-block;
    background-color: #ffeab4;
    border-radius: 4px;
    padding: 2px 5px;
    font-size: 14px;
    font-weight: 600;
    color: #1e1e1e;
    margin-bottom: 6px;
    text-transform: capitalize;
    margin-top: 15px;
  }
  .newData.oldData {
    background-color: #d4d4d4;
  }

  .trackChangeValue {
    background-color: rgba(255, 240, 202, 0.5);
    border-radius: 10px;
  }
  .trackChangeValue.old {
    background-color: #f7f7f7;
    .claimDetailsBox {
      text-decoration: line-through;
      .claimText {
        color: #878888;
      }
    }
    .claimId {
      color: #878888;
    }
    .claimBoxBrdr .damStatus span {
      color: #878888;
    }
  }
  .modulePreviewContainer {
    border: none;
  }
  .moduleClaimSection {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.cmsdTrackContainer .trackChangeValue.old .claimDetailsBox .captionText {
  color: #878888;
}
.cmsdTrackContainer .trackChangeValue.old .claimDetailsBox .relatedText {
  color: #878888;
}
/*Track changes styles ends */
.claimId img {
  margin-right: 2px;
  align-items: center;
  margin-top: -2px;
}
