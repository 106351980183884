@import "../base/universal";
.elementsBlockContainer {
  height: calc(100vh - 300px);
}
.elementxBox {
  border-radius: 12px;
  background-color: #f2f6fa;
  border: solid 1px #f2f6fa;
  height: calc(100vh - 298px);
  padding: 15px 15px 0px 15px;
  &.elementxBoxBrdr {
    border-radius: 12px;
    border: solid 1px #e6e6eb;
    background-color: #fff;
    .title {
      color: #1e1e1e;
    }
  }
  .title {
    font-size: 14px;
    font-weight: 700;
    color: #1e1e1e;
    img {
      margin: 0 12px 0 5px;
    }
  }
}
.moduleFooterRow {
  box-shadow: 1px -3px 10px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
  padding-top: 14px;
  padding-bottom: 14px;
}
.addElementsOutline {
  border-radius: 6px;
  border: dashed 1px #203662;
  text-align: center;
}
.countRounded {
  width: 32px;
  height: 32px;
  background-color: #d1e6e8;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  padding-top: 6px;
  font-weight: 600;
  color: #1e1e1e;
}
.elementSectionTitle {
  font-size: 14px;
  font-weight: 600;
  color: #54565b;
}
