@import "../base/universal";

.multi-select-list {
  //height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 2px;
}
.multi-select-list .MuiListItem-root {
  //padding-top: 0px;
  padding-bottom: 0px;
}
.MuiList-padding {
  padding-bottom: 0px;
}
.multi-select-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.multi-select-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}
.multi-select-list::-webkit-scrollbar {
  background-color: $imc-white-color;
}
.mul-sel-list-container .MuiPaper-root {
  width: 248px;
  border-radius: 12px;
  box-shadow: 6px 6px 54px 0 #eef8ff;
}

.multi-select-list .MuiFormControlLabel-root {
  margin-right: 0px;
  margin-bottom: 0px;
}
.multi-select-list .MuiMenuItem-root {
  font-size: $font-small;
  color: $imc-black-color !important;
  white-space: normal;
}

.multi-select-list .MuiIconButton-root {
  padding: 0px 9px;
}

.multi-select-list .MuiTypography-body1 {
  font-size: $font-small;
  color: $imc-black-color !important;
}

.multi-select-list .MuiIconButton-root {
  padding: 0px 6px 0px 9px;
}
