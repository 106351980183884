/* DropDownButtons.module.scss */

.dropdown-button {
  border-radius: 16px;
  background-color: #d1e6e6;
  color: #1e1e1e;
  font-size: 12px;
  font-weight: 600;
  border: none;
  width: fit-content !important;
  &.show,
  &.show:hover,
  &.show:focus {
    background-color: #d1e6e6 !important;
    color: #1e1e1e !important;
    border-color: transparent !important;
  }

  &:hover,
  &:focus {
    background-color: #d1e6e6;
    color: #1e1e1e;
  }
  &.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }
  &.show > .btn-primary.dropdown-toggle {
    color: #1e1e1e !important;
    border-color: #d1e6e6 !important;
    background-color: #d1e6e6 !important;
  }
  .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none !important;
  }
  .btn-primary:not(:disabled):not(.disabled):active {
    color: #1e1e1e;
  }
  .btn-primary:focus {
    box-shadow: none !important;
    background-color: #d1e6e6 !important;
    border-color: transparent !important;
  }
  .dropdown-toggle {
    background-color: transparent;
    border: none;
    min-width: auto !important;
    min-height: auto !important;
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: #1e1e1e;
    border-radius: 16px;
    padding: 8px 16px;
    min-height: auto;
    cursor: pointer;
    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 1px;
      content: "";
      //background-image: url("./img/blue-down-arrow.svg.svg");
      background-image: url("../../img/blue-down-arrow.svg");
      background-repeat: no-repeat;
      border-top: none !important;
      border-right: none !important;
      border-bottom: none !important;
      border-left: none !important;
      width: 11px;
      height: 6px;
      background-size: 11px;
    }
  }

  &:hover {
    color: #d1e6e6 !important;
  }
  &:focus {
    box-shadow: none;
    border: none;
    background-color: #d1e6e6 !important;
  }
  &.show .btn-primary.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 1px;
    content: "";
    //background-image: url("./img/blue-down-arrow.svg.svg");
    background-image: url("../../img/blue-up-arrow.svg.svg");
    background-repeat: no-repeat;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
    width: 11px;
    height: 6px;
    background-size: 11px;
  }
}

.activeButton {
  //background-color: #f2f6fa !important; /* Highlight color */
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #203662 !important;
}

.btnLink {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  &:hover {
    text-decoration: none;
    background-color: #f2f6fa !important;
  }
  &:active,
  &:focus {
    box-shadow: none;
    border: none;
    outline: none;
  }
}
.dropdown-button .dropdown-item {
  border: none;
  cursor: pointer;
  outline: none;

  &:hover {
    text-decoration: none;
  }
  &:active,
  &:focus {
    box-shadow: none;
    border: none;
    outline: none;
  }
}
.dropdown-button .dropdown-menu {
  padding: 0px !important;
}
