@import "../base/universal";

.tf-rounded-search {
  border-radius: 16px;
  border: solid 1px $imc-border-color;
  word-wrap: normal;
  height: 32px !important;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  margin: 0px;
  input {
    border: none !important;
    background-color: transparent;
    width: 100%;
    color: $imc-black-color !important;
    height: 100%;
    padding: 8px 6px;
    font-size: $font-small !important;
  }
  input:focus {
    outline: 0;
  }
  input::placeholder {
    font-size: $font-small !important;
    color: $imc-aubergine-color !important;
    opacity: 0.3;
  }
}

.search-icon {
  height: 12px;
  width: 12px;
}

.tf-rounded-search {
  overflow: hidden;
  transition: width 0.3s ease;
  transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

// .d-flex.expanded .tf-rounded-search {
//   width: 385px;
// }

/* dashboardsFilterStyles.css */
.tf-rounded-search.expandable input[type="text"] {
  width: 100%;
  transition: width 0.8s ease-in-out;
}
.tf-rounded-search.expandable input[type="text"]:focus {
  width: 317px;
}

@media screen and (max-width: 1280px) {
  .tf-rounded-search.expandable input[type="text"]:focus {
    width: 217px;
  }
}

// .search-input {
//   color: #727275 !important;
//   //margin-left: 5px !important;
//   font-size: 10px !important;
//   margin-right: 0px;
// }
// .search-input::placeholder {
//   color: #727275 !important;
//   font-size: 10px !important;
// }
