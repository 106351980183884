@import "../base/universal";
.paginationBtn {
  background-repeat: no-repeat;
  width: 23px;
  height: 20px;
  border: 0;
  background-color: transparent;
}
.btnPaginationLeft {
  background-image: url("../../img/rectLeftSlideActive.svg");
  margin-right: 8px;
  &:disabled {
    background-image: url("../../img/rectLeftSlide.svg");
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.btnPaginationRight {
  background-image: url("../../img/rectRightSlideActive.svg");
  margin-right: 8px;
  &:disabled {
    background-image: url("../../img/rectRightSlide.svg");
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.lableText {
  font-size: 12px;
  color: #222;
  margin-left: 4px;
  margin-right: 4px;
  line-height: 1.9;
}
.sliderInput {
  width: 23px;
  height: 20px;
  border-radius: 4px;
  border: solid 1px #e6e6eb;
  background-color: #fff;
}

.customMargin {
  button {
    margin: 0 12px;
  }
}
