$primary-color: #203662;
$close-button-color: red;

.box-container {
  position: fixed;
  right: 12px;
  bottom: 12px;
  z-index: 9999;
  .card {
    width: 320px;
    // max-height: 354px;
    // min-height: 252px;
    padding: 30px;
    border-radius: 6px;
    background-color: $primary-color;
    position: relative;

    .close-button {
      right: 20px;
      color: white;
      cursor: pointer;
    }

    .step-text {
      color: white;
      font-weight: normal;
      margin-bottom: 6px;
      opacity: 0.7;
    }

    .styled-paragraph {
      color: white;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .styled-paragraph-40 {
      color: white;
      font-weight: bold;
      margin-bottom: 40px;
    }
    .styled-paragraph-12 {
      color: white;
      font-weight: bold;
      margin-bottom: 12px;
    }
  }
  .checkbox-label {
    display: inline-block;
    margin-right: 20px;
  }

  .checkbox-text {
    font-size: 14px;
    color: white;
    vertical-align: middle;
    margin-right: 6px;
    margin-bottom: 0;
  }
  input[type="checkbox"] {
    accent-color: #ffff;
    color: $primary-color;
    font-weight: normal;
  }

  .close-button {
    cursor: pointer;
    font-size: 14px;
    color: white;
    vertical-align: middle;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
}

/* Styled Checkbox */
.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.checkbox-container {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid #ffff;
  cursor: pointer;
  margin-right: 6px;
  margin-top: 3px;
  margin-bottom: none;
}

/* Checked effect */
.styled-checkbox:checked ~ .checkbox-container {
  background-color: #ffff;
}

/* Checkmark */
.checkbox-container::after {
  content: "";
  position: absolute;
  display: none;
  left: 3px;
  top: 0px;
  width: 4.5px;
  height: 8.5px;
  border: 1px solid #203662;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Display checkmark when checked */
.styled-checkbox:checked ~ .checkbox-container::after {
  display: block;
}
