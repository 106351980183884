@import "../base/universal";

.scroll-container {
  overflow-x: hidden;
  white-space: nowrap;
  width: calc(100% - 10px);
  padding-left: 0px;
  padding-right: 0px;
  flex-grow: 1;
  margin: 0 0 0 0px;
  /* height: 50px;*/
  padding-top: 12px;
  padding-bottom: 12px;
}
.custom-chip {
  display: inline-block;
  padding: 0 15px;
  height: 32px;
  font-size: 12px;
  border-radius: 25px;
  border: 1px solid #d4d4d4;
  background-color: #fff;
  margin-right: 12px;
  padding-top: 7px;
  color: #1e1e1e;
}
.close-icon {
  width: 10px;
  height: 10px;
  margin-left: 6px;
  cursor: pointer;
}
.arrow-navigation {
  margin: auto;
  height: 32px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: linear-gradient(
    to right,
    rgba(245, 245, 247, 0),
    #f5f5f7 51%,
    #f5f5f7
  );
}
.arrow-navigation-right {
  margin: auto;
  height: 32px;
  padding-left: 10px;
  background-image: linear-gradient(
    to left,
    rgba(245, 245, 247, 0),
    #f5f5f7 51%,
    rgba(245, 245, 247, 0)
  );
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.resetl-all {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
}
