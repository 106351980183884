.moduleStepsRow {
  background-color: #f9f9fa;
  border-top: solid 1px #e6e6ec;
  border-bottom: solid 1px #e6e6ec;
  padding-top: 15px;
}
.moduleStepsBlock {
  border-bottom: solid 2px #878888;
  color: #1e1e1e;
}

.steps {
  color: #1e1e1e;
  padding-bottom: 17px;
  position: relative;
  font-weight: 600;

  span {
    display: inline-block;
  }
}
.step01 {
  // margin-right: 91px;
  span {
    margin-left: -42px;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: -8px;
    width: 16px;
    height: 16px;
    background-color: #203662;
    border-radius: 50%;
    animation: ripples 1s linear 1;
    // animation: ripples 1.5s ease-out 1;
    box-shadow: 0 0 0 2px rgba(3, 78, 162, 0.3), 0 0 0 4px rgba(3, 78, 162, 0.1);
  }
  &.active {
    &::before {
      content: "";
      position: absolute;
      bottom: -8px;
      width: 16px;
      height: 16px;
      background-color: #203662;
      border-radius: 50%;
      animation: ripples 1s linear 1;
      // animation: ripples 1.5s ease-out 1;
      box-shadow: 0 0 0 2px rgba(3, 78, 162, 0.3),
        0 0 0 4px rgba(3, 78, 162, 0.1);
    }
  }
}
@keyframes ripples {
  to {
    // box-shadow: 0 0 0 16px rgba(0, 0, 0, 0.2), 0 0 0 32px rgba(0, 0, 0, 0.2),
    //   0 0 0 48px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 2px rgba(3, 78, 162, 0.3), 0 0 0 4px rgba(3, 78, 162, 0.2),
      0 0 0 6px rgba(3, 78, 162, 0.1);
  }
}

.step02 {
  span {
    margin-right: -32px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -8px;
    width: 16px;
    height: 16px;
    background-color: #878888;
    border-radius: 50%;
  }
  &.active {
    &::after {
      // content: "";
      // position: absolute;
      // bottom: -8px;
      // width: 16px;
      // height: 16px;
      // background-color: #203662;
      // border-radius: 50%;
      position: absolute;
      bottom: -8px;
      width: 16px;
      height: 16px;
      background-color: #203662;
      border-radius: 50%;
      animation: ripples 1s linear 1;
      // animation: ripples 1.5s ease-out 1;
      box-shadow: 0 0 0 2px rgba(3, 78, 162, 0.3),
        0 0 0 4px rgba(3, 78, 162, 0.1);
    }
  }
}

.moduleStepsPreview {
  .moduleStepsBlock {
    border-bottom: solid 2px #203662 !important;
    color: #1e1e1e;
  }
  .step01,
  .step01_01 {
    &::before {
      content: "";
      position: absolute;
      bottom: -8px;
      width: 16px;
      height: 16px;
      background-color: transparent;
      background-image: url("../../img/complete.svg");
      background-repeat: no-repeat;
      border-radius: 50%;
      box-shadow: none;
    }
  }
  .step02 {
    span {
      margin-right: -32px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -8px;
      width: 16px;
      height: 16px;
      background-color: #203662;
      border-radius: 50%;
      animation: ripples 1s linear 1;
      // animation: ripples 1.5s ease-out 1;
      box-shadow: 0 0 0 2px rgba(3, 78, 162, 0.3),
        0 0 0 4px rgba(3, 78, 162, 0.1);
    }
  }
}
.step01_01 {
  // margin-right: 91px;
  span {
    margin-left: -70px;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: -8px;
    width: 16px;
    height: 16px;
    background-color: #203662;
    border-radius: 50%;
    animation: ripples 1s linear 1;
    // animation: ripples 1.5s ease-out 1;
    box-shadow: 0 0 0 2px rgba(3, 78, 162, 0.3), 0 0 0 4px rgba(3, 78, 162, 0.1);
  }
  &.active {
    &::before {
      content: "";
      position: absolute;
      bottom: -8px;
      width: 16px;
      height: 16px;
      background-color: #203662;
      border-radius: 50%;
      animation: ripples 1s linear 1;
      // animation: ripples 1.5s ease-out 1;
      box-shadow: 0 0 0 2px rgba(3, 78, 162, 0.3),
        0 0 0 4px rgba(3, 78, 162, 0.1);
    }
  }
}
.activeBrStepOne {
  .step01_01 {
    &::before {
      content: "";
      position: absolute;
      bottom: -8px;
      width: 16px;
      height: 16px;
      background-color: transparent;
      background-image: url("../../img/complete.svg");
      background-repeat: no-repeat;
      border-radius: 50%;
      box-shadow: none;
    }
  }
}

.borderDefault {
  border-bottom: solid 2px #878888;
  color: #1e1e1e;
  height: 36px;
}
.borderActive {
  border-bottom: solid 2px #203662;
  color: #1e1e1e;
}
.borderActiveBussinessRules {
  border-bottom: solid 2px #378a05;
  color: #1e1e1e;
}
.step01Width {
  padding-right: 90px;
  position: relative;
}
.step02Width {
  position: relative;
  padding-right: 67px;
}
.step03Width {
  position: relative;
}

.circleDefault::after {
  content: "";
  position: absolute;
  // bottom: -8px;
  width: 16px;
  height: 16px;
  background-color: #878888;
  border-radius: 50%;
}
.circleCurrent::before {
  content: "";
  position: absolute;
  // bottom: -8px;
  width: 16px;
  height: 16px;
  background-color: #203662;
  border-radius: 50%;
  animation: ripples 1s linear 1;
  // animation: ripples 1.5s ease-out 1;
  box-shadow: 0 0 0 2px rgba(3, 78, 162, 0.3), 0 0 0 4px rgba(3, 78, 162, 0.1);
}
.circleActive::before {
  content: "";
  position: absolute;
  // bottom: -8px;
  width: 16px;
  height: 16px;
  background-color: transparent;
  background-image: url("../../img/complete.svg");
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: none;
}
.one {
  span {
    margin-left: -65px;
  }
  &::before {
    bottom: -8px;
    width: 16px;
  }
  &::after {
    bottom: -8px;
    left: 0;
  }
}
.two {
  span {
    margin-left: -50px;
  }
  &::before {
    bottom: -8px;
    width: 16px;
  }
  &::after {
    bottom: -8px;
    left: 0;
  }
}
.three {
  span {
    right: -15px;
    position: absolute;
    z-index: 1;
  }
  &::before {
    bottom: -8px;
    width: 16px;
    right: 0;
  }
  &::after {
    bottom: -8px;
    right: 0;
  }
}
